import React, { useEffect } from "react"

const BasicForm = () => {
  const callTypeForm = () => {
    var js,
      q,
      d = document,
      gi = d.getElementById,
      ce = d.createElement,
      gt = d.getElementsByTagName,
      id = "typef_orm",
      b = "https://embed.typeform.com/"
    if (!gi.call(d, id)) {
      js = ce.call(d, "script")
      js.id = id
      js.src = b + "embed.js"
      q = gt.call(d, "script")[0]
      q.parentNode.insertBefore(js, q)
    }
  }

  useEffect(() => {
    callTypeForm()
  })

  return (
    <div id="form" className="bg-gray-100 pt-12 pb-20 md:pt-16 md:pb-24">
      <div className="container mx-auto text-center">
        <div
          id="typeform"
          className="typeform-widget bg-gray-300 border"
          data-url="https://form.typeform.com/to/Y7eEYnqI?typeform-medium=embed-snippet"
          style={{ width: "100%", height: "500px", borderRadius: "10px" }}
        ></div>
      </div>
    </div>
  )
}

export default BasicForm
