import React from "react"

const BasicHero = () => {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto pt-16 pb-28 md:pt-24 md:pb-36 text-center flex flex-col justify-center flex-shrink">
        <h2 className="md:text-5xl text-3xl font-special font-bold mb-8 sm:mb-10">
          Pas d’opération, pas de frais.
          <br />
          Aucun engagement.
        </h2>
        <h3 className="md:text-2xl text-xl font-thin text-gray-700">
          Pas de coûts cachés ! La transparence fait partie de l’ADN de Bulle.co
          !
          <br />
          Le référencement sur la plateforme est gratuit.
          <br />
          <br />
          <div className="flex justify-center items-center mt-14 flex-wrap md:flex-nowrap">
            <div className="md:w-1/5 mb-6 md:mb-0 md:mr-6 flex justify-center">
              <div className="p-8 md:p-12 md:text-4xl text-2xl font-bold font-special bg-indigo-600 rounded self-center text-white shadow">
                5%
              </div>
            </div>
            <div className="text-center md:w-3/5 md:text-left font-black text-2xl md:text-3xl">
              C'est la commission que Bulle.co prélève sur les ventes réalisées
              sur la plateforme.
            </div>
          </div>
        </h3>
      </div>
    </div>
  )
}

export default BasicHero
