import React from "react"

const Footer = () => {
  return (
    <div className="container mx-auto py-4">
      <div className="flex justify-center">
        <a
          className="text-pretotype"
          href="https://pretotype.fr"
          target="_blank"
          rel="noreferrer"
        >
          pretotype.fr
        </a>
      </div>
    </div>
  )
}

export default Footer
