import React from "react"

const BasicCallToAction = () => {
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto pt-12 pb-20 md:pt-18 md:pb-24 text-center">
        <a
          className="rounded shadow-lg bg-transparent border-4 hover:border-indigo-800 border-indigo-600 px-6 py-4 text-xl font-bold"
          href="#form"
        >
          s'inscrire maintenant
        </a>
      </div>
    </div>
  )
}

export default BasicCallToAction
