import React from "react"

const BasicHero = () => {
  return (
    <div className="container mx-auto h-80v text-center flex flex-col justify-center flex-shrink">
      <div className="sm:text-3xl text-2xl font-thin font-special mb-12 bg-indigo-600 rounded py-2 px-4 self-center text-white shadow-lg">
        Bulle<span className="text-indigo-300">.</span>Co
      </div>
      <h1 className="sm:text-6xl text-3xl font-black text-gray-800 mb-10 leading-tight">
        Elargissez votre <span className="text-indigo-600">visibilité</span> et
        augmentez votre{" "}
        <span className="text-indigo-600">chiffre d’affaire</span>.<br />
        En 1 clic.
      </h1>
      <h2 className="sm:text-2xl text-xl font-thin text-gray-700">
        BulleCo vous offre un portail de vente au près des Comités Sociaux et
        Économiques des entreprises de France.
      </h2>
    </div>
  )
}

export default BasicHero
