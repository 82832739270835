import React from "react"

const BasicFeatures = () => {
  return (
    <div className="container mx-auto pt-16 pb-28 md:pt-24 md:pb-28 text-center">
      <h2 className="md:text-5xl text-3xl font-special font-bold mb-8 sm:mb-16">
        Choisissez Bulle.Co et commencez à bénéficier des avantages dès
        maintenant
      </h2>
      <div className="flex flex-wrap justify-center">
        <div className="md:w-1/3 px-6 mb-16 md:text-xl text-xl font-bold text-gray-700">
          Proposez les produits directement à des milliers de salariés
        </div>
        <div className="md:w-1/3 px-6 mb-16 md:text-xl text-xl font-bold text-gray-700">
          Réduisez les coûts de transport avec des opérations groupées
        </div>
        <div className="md:w-1/3 px-6 mb-16 md:text-xl text-xl font-bold text-gray-700">
          Gérez plus facilement les opérations avec notre plateforme
        </div>
        <div className="md:w-1/3 px-6 mb-16 md:text-xl text-xl font-bold text-gray-700">
          Faite vous connaitre et gagner en notoriété
        </div>
        <div className="md:w-1/3 px-6 mb-16 md:text-xl text-xl font-bold text-gray-700">
          Bénéficiez d’un nouveau canal de vente
        </div>
        <div className="md:w-1/3 px-6 mb-16 md:text-xl text-xl font-bold text-gray-700">
          Réduisez les coût d’acquisition client
        </div>
        <div className="md:w-1/3 px-6 mb-16 md:text-xl text-xl font-bold text-gray-700">
          Centralisez les opérations commerciales avec les CSE
        </div>
      </div>
    </div>
  )
}

export default BasicFeatures
