import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import BasicHero from "../components/BasicHero.js"
import BasicValueProposition from "../components/BasicValueProposition.js"
import BasicPricing from "../components/BasicPricing.js"
import BasicCallToAction from "../components/BasicCallToAction.js"
import BasicFeature from "../components/BasicFeature.js"
import BasicForm from "../components/BasicForm.js"
import BasicFooter from "../components/BasicFooter.js"

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" />
    <BasicHero />
    <BasicValueProposition />
    <BasicPricing />
    <BasicCallToAction />
    <BasicFeature />
    <BasicForm />
    <BasicFooter />
  </Layout>
)

export default IndexPage
