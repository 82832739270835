import React from "react"

const BasicValueProposition = () => {
  return (
    <div className="container mx-auto pt-18 pb-28 md:pt-24 md:pb-36 text-center">
      <div className="rounded-lg bg-indigo-600 shadow-xl py-6 px-6 sm:pt-14 sm:pb-20 sm:px-16">
        <h2 className="text-white md:text-5xl text-3xl font-special font-bold mb-8 sm:mb-16">
          Bénéficiez ainsi de:
        </h2>
        <div className="flex flex-col">
          <div className="flex justify-center items-center mb-10">
            <div className="w-1/5 flex justify-center">
              <div className="rounded-full bg-white flex justify-center items-center text-2xl md:text-4xl font-bold h-12 w-12 md:h-16 md:w-16 mr-4">
                1
              </div>
            </div>
            <div className="w-4/5 md:text-2xl text-xl text-left text-white">
              D'un réseau de CSE partenaires (CSE de votre région et de France
              entière)
            </div>
          </div>
          <div className="flex justify-center items-center mb-10">
            <div className="w-1/5 flex justify-center">
              <div className="rounded-full bg-white flex justify-center items-center text-2xl md:text-4xl font-bold h-12 w-12 md:h-16 md:w-16 mr-4">
                2
              </div>
            </div>
            <div className="w-4/5 md:text-2xl text-xl text-left text-white">
              D'une expertise dans le fonctionnement des comités sociaux et
              économiques
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="w-1/5 flex justify-center">
              <div className="rounded-full bg-white flex justify-center items-center text-2xl md:text-4xl font-bold h-12 w-12 md:h-16 md:w-16 mr-4">
                3
              </div>
            </div>
            <div className="w-4/5 md:text-2xl text-xl text-left text-white">
              De la possibilité d’organiser des opérations commerciales de
              plusieurs sortes (commandes groupées, partenariats, expos ventes,
              participation aux foires et marchés, dépôt vente…)
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicValueProposition
